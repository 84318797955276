
  import ListMixin from "../../Mixin/ListMixin";
  import DynamicTable from "./DynamicTable";

  export default {
    name: "DynamicList",
    mixins: [ListMixin],
    components: { DynamicTable },
    props: {
      dynModuleName: null,
      isPlanBoardMode: null,
      exData: null,
      exDynFieldsAll: null,
      onRowClickOver: null,
      onRowDblClickOver: null,
      onRowClickOverWorkOrder: null,
      planboardListSettings: null
    },
    data: () => ({
      title: '',
      filterByName: "",
      showTableRefresh: 0,
      filtersSelectModel: null,
      showAddFilter: false,
      filterSave: {name: '', shared: false, locked: false, default: false, columns: [], filters: {}, pagination: {rowsPerPage : 10}},
      filterEditName: '',
      filterEditShared: false,
      filterEditLocked: false,
      filterEditSelect: {},
      showHeader: true
    }),
    methods: {
      addForm() {
        this.$emit('onAddNewAppointment');
      },
      onSavePlanboardListSettings(){
        this.showHeader = !this.showHeader
        const settings = {
          showListHeader: this.showHeader,
        }
        this.$emit('onSavePlanboardListSettings', settings);
      }
    },

    computed: {

    },

    watch: {
      planboardListSettings(value) {
        this.showHeader = value.showListHeader
      }
    }

  };
