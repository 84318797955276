
import {mapState} from "vuex";
import DynamicForm from '@/components/Forms/DynamicForm';

export default {
  name: "ModalForDoubleForm",
  components: {
    DynamicForm
  },
  props: {
    appointmentRecordID: null,
    workOrderRecordID: null,
    initAppointmentParams: null,
    readOnlyWorkOrder: null
  },
  data () {
    return {
      tab: 'appointment',
      appointmentFormDataData: null,
      appointmentID: this.appointmentRecordID,
      workOrderID: this.workOrderRecordID,
      showSavePromtDialog: false
    }
  },
  methods: {
    resetShowModalForm() {
      this.$emit('resetShowModalForm')
    },
    onCloseForm() {
      this.$emit('resetShowModalForm')
    },
    getChangedData(data) {
      this.appointmentFormDataData = data
      if (this.appointmentFormDataData && this.appointmentFormDataData.ID) {
        this.appointmentID = this.appointmentFormDataData.ID
      }
      if (this.appointmentFormDataData && this.appointmentFormDataData.workOrder) {
        this.workOrderID = this.appointmentFormDataData.workOrder.ID
      }
    },
    onTabInput(val) {
      if (val === 'workOrder' && !this.appointmentID) {
        this.tab = 'appointment'
        this.showSavePromtDialog = true
      }
    }
  },
  computed: {
    ...mapState(["dynamicModules"]),

    appoinmentTitle() {
      const appoinmentModuleData = this.dynamicModules && this.dynamicModules['appointments'] ? this.dynamicModules['appointments'] : {} ;
      return appoinmentModuleData ? appoinmentModuleData.singularName || appoinmentModuleData.name : 'appointment';
    },
    workorderTitle() {
      const workorderModuleData = this.dynamicModules && this.dynamicModules['workOrders'] ? this.dynamicModules['workOrders'] : {} ;
      return workorderModuleData ? workorderModuleData.singularName || workorderModuleData.name : 'workOrder';
    }
  }
}
