import { doc, writeBatch } from 'firebase/firestore';
import AbModel, {Fields as AbModelFields, User} from "@/components/Models/AbModel";
import store from "../../store";
import SelectModel from "@/components/Models/SelectModel";
import moment from "moment";
import { Timestamp } from '@firebase/firestore';
import db from "@/db";

export class Account {
  ID = "";
  name = "";
}

export class WorkOrder {
  ID = "";
  name = "";
}

export class Fields extends AbModelFields {
  name = ""; //M2000001
  actualEndDateTime: Date | null = null;
  actualStartDateTime: Date | null = null;
  plannedEndDateTime: Date = new Date(); // from drag end drop
  plannedStartDateTime: Date = new Date();
  status = SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned;
  // users: Array<string> = []; //["Ec7ZFwUC1pxUq4f8GeS8"],
  serviceEngineerMoveToAll = true;
  // always prefill with this data
  module = {
    ID: "workOrders",
    name: "workOrders",
  };
  // Related field to (module as selected in appointment.module)
  workOrder: WorkOrder = new WorkOrder();
  // Related field to (accounts)
  // copy over from workOrder.customer
  account: Account = new Account();
  // service fields
  warning = false;
  changed = false;
}

export class UpdatePlanedFields {
  plannedEndDateTime: any = new Date(); // from drag end drop
  plannedStartDateTime: any = new Date();
  users: Array<string> = []; //["Ec7ZFwUC1pxUq4f8GeS8"],
  warning = false;
  changed = false;
  resource = "";
  status = "";
}

class AppointmentModel extends AbModel {
  module = "appointments";

  fields(): Fields {
    return new Fields();
  }

  defaultValidation = {

  };

  resourcesModule = store.state.settings.planboardSchedulerSettings.resourcesModule;
  resourcesModuleField = store.state.allModules[this.resourcesModule].singularName;

  async save(data, callBack = () => {}) {
    this.checkSelectUser(data);
    if (data.ID) {
      if (this.canDeleteTheAppointment(data)) {
        await this.update(data.ID, data, callBack);
        return this.delete(data.ID);
      } else {
        return this.update(data.ID, data, callBack);
      }
    } else {
      return this.set(data, callBack);
    }
  }

  canDeleteTheAppointment(data) {
    let res = false;
    const noUsers = !data.users || data.users.length === 0;
    const noObjects = !data.objects || data.objects.length === 0;
    const inStateStatus = SelectModel.notDeleteAppointmentStatuses.includes(data.status);
    if (noUsers && noObjects && !inStateStatus) {
      res = true;
    }
    
    return res;
  }

  createFromWorkOrder(param) {
    const task = this.fields();
    task.status = SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned;
    task.workOrder.ID = param.ID;
    task.workOrder.name = param.name || "";
    this.convertToTimeStamp(task, this.fields());
    return this.set(this.convertToObject(task));
  }

  async copyDayPilot(event) {
    const task = {};
    const appointment = await this.getByID(event.ID, this.module);
    for (const field in appointment) {
      task[field] = appointment[field];
    }
    this.regenerateFieldsAfterCopy(task);
    return this.set(this.convertToObject(task));
  }

  regenerateFieldsAfterCopy(task) {
    delete task.id;
    delete task.ID;
    task.name = null;
    task.createdAt = Timestamp.now();
    task.changedAt = Timestamp.now();
    task.createdBy = JSON.parse(JSON.stringify(new User()));
    task.changedBy = JSON.parse(JSON.stringify(new User()));
  }

  // drag and drop on daypilot
  async createFromDayPilot(event, useAppointmentHours) {
    const appointment = await this.getByID(event.appoID, this.module);
    let hours = 0;
    if(useAppointmentHours && appointment && appointment.estimatedTimeOnLocationHour) {
      hours = appointment.estimatedTimeOnLocationHour
    }
    if(!useAppointmentHours && event.order.wo) {
      hours = event.order.wo.estimatedTimeOnLocationHour 
    }
    let start = event.start.toDateLocal();
    let end = event.end.toDateLocal();
    end = hours ? moment(start).add(hours, "h").toDate() : end;
    end = Timestamp.fromDate(end);
    start = Timestamp.fromDate(start);
    const task = new UpdatePlanedFields();
    task.status = SelectModel.mandatoryAppointmentStatuses.statusWhenReleasedInPlanboard;
    task.plannedStartDateTime = start;
    task.plannedEndDateTime = end;

    const userOrObject = await this.getByID(event.resource, this.resourcesModule);
    task[this.resourcesModule] = (appointment as any)[this.resourcesModule];
    if (!this.resourcesModuleField) {
      console.error('No singular name for the module - ' + this.resourcesModule);
    }
    task[this.resourcesModuleField || 'user'] = {ID: userOrObject.ID, name: userOrObject.name};

    if (task[this.resourcesModule].indexOf(event.resource) === -1) {

      if (!appointment['__' + this.resourcesModule]) {
        appointment['__' + this.resourcesModule] = [];
      }
      task['__' + this.resourcesModule] = appointment['__' + this.resourcesModule];
      task['__' + this.resourcesModule].push({ID: userOrObject.ID, name: userOrObject.name});
      task[this.resourcesModule].push(event.resource);
    }
    return this.update(event.appoID, this.convertToObject(task));
  }

  async createMultipleAppointmentsFromDayPilot(event, schedulerSettings) {
    const appointment = await this.getByID(event.appoID, this.module);
    const userOrObject = await this.getByID(event.resource, this.resourcesModule);
    appointment[this.resourcesModuleField || 'user'] = {ID: userOrObject.ID, name: userOrObject.name};
    let hours = 0;
    if(schedulerSettings.useAppointmentHours && appointment && appointment.estimatedTimeOnLocationHour) {
      hours = appointment.estimatedTimeOnLocationHour
    }
    if(!schedulerSettings.useAppointmentHours && event.order.wo) {
      hours = event.order.wo.estimatedTimeOnLocationHour 
    }

    let start = event.start.toDateLocal();
    let end = event.end.toDateLocal();

    const startingHour = start.getHours()
    const hoursToAdd = schedulerSettings.workDayEnds - startingHour;
    let remainingHours = hours - hoursToAdd
    const workingHoursPerDay = schedulerSettings.workDayEnds - schedulerSettings.workDayStarts
    end = moment(start).add(hoursToAdd, "h").toDate()
    end = Timestamp.fromDate(end);
    start = Timestamp.fromDate(start);
    const task = await this.createNewTask(start, end, event, appointment)
    this.update(event.appoID, this.convertToObject(task));

    const timeToExecuteFunction = Math.ceil(remainingHours / workingHoursPerDay)
    let index = 0
    let dateToCheck = event.start.toDateLocal()
    const batch = writeBatch(db)
    while (index < timeToExecuteFunction) {
      let appointmentTime = workingHoursPerDay
      if(remainingHours < workingHoursPerDay) {
        appointmentTime = remainingHours
      }
      remainingHours -= appointmentTime
      let daysToAdd = 1
      if((moment(dateToCheck).format("dddd") === "Friday" || moment(dateToCheck).format("dddd") === "Saturday") && !schedulerSettings.businessWeekends) {
        daysToAdd += 2
      }
      const scheduleDate: any = moment(dateToCheck).add(daysToAdd, "d").toDate()
      dateToCheck = scheduleDate
      const newAppointment = this.createExtraAppointment(appointment, appointmentTime, scheduleDate, schedulerSettings)
      const docID = db.collection(this.tenant()).doc().id
      newAppointment.ID = docID
      const ref = doc(db, `tenants/${store.state.tenantID}/modules/appointments/records/${docID}`)
      batch.set(ref, newAppointment)
      index += 1
    }
    await batch.commit()
  }

  createExtraAppointment(appointment, remainingHours, startFirstAppointment, schedulerSettings) {
    let startDate: any = moment(startFirstAppointment).set({hour: schedulerSettings.workDayStarts,minute:0,second:0}).toDate()
    let endDate: any = moment(startDate).add(remainingHours, "h").toDate()
    startDate = Timestamp.fromDate(startDate);
    endDate = Timestamp.fromDate(endDate);
    appointment.plannedStartDateTime = startDate;
    appointment.plannedEndDateTime = endDate;
    appointment.status = SelectModel.mandatoryAppointmentStatuses.statusWhenReleasedInPlanboard
    this.regenerateFieldsAfterCopy(appointment);
    return this.convertToObject(appointment);
  }

  async createNewTask(start, end, event, appointment) {
    const task = new UpdatePlanedFields();
    task.status = SelectModel.mandatoryAppointmentStatuses.statusWhenReleasedInPlanboard;
    task.plannedStartDateTime = start;
    task.plannedEndDateTime = end;

    const userOrObject = await this.getByID(event.resource, this.resourcesModule);
    task[this.resourcesModule] = (appointment as any)[this.resourcesModule];
    if (!this.resourcesModuleField) {
      console.error('No singular name for the module - ' + this.resourcesModule);
    }
    task[this.resourcesModuleField || 'user'] = {ID: userOrObject.ID, name: userOrObject.name};

    if (task[this.resourcesModule].indexOf(event.resource) === -1) {

      if (!appointment['__' + this.resourcesModule]) {
        appointment['__' + this.resourcesModule] = [];
      }
      task['__' + this.resourcesModule] = appointment['__' + this.resourcesModule];
      task['__' + this.resourcesModule].push({ID: userOrObject.ID, name: userOrObject.name});
      task[this.resourcesModule].push(event.resource);
    }
    return task
  }

  async updateFromDayPilot(a, newResource) {
    const event = a.event.data;
    const task = new UpdatePlanedFields();
    const docID = event.ID.split('_||_')[0];
    const appointment = await this.getByID(docID, this.module);

    task.plannedStartDateTime = Timestamp.fromDate(
      a.start.toDateLocal()
    );
    task.plannedEndDateTime = Timestamp.fromDate(
      a.end.toDateLocal()
    );
    task.status = event.status;

    if (newResource) {
      task[this.resourcesModule] = (appointment as any)[this.resourcesModule];
      task[this.resourcesModule] = task[this.resourcesModule].filter(d => d);
      if (task[this.resourcesModule].length === 1) {
        // only one user in the appointment, set user
        const userOrObject = await this.getByID(newResource, this.resourcesModule);
        if (userOrObject) {
          task[this.resourcesModuleField || 'user'] = {ID: userOrObject.ID, name: userOrObject.name};
        }
        task[this.resourcesModule] = [newResource];
        task['__'+this.resourcesModule] = [{ID: userOrObject.ID, name: userOrObject.name}];
      } else {
        // no move when multi assigned
        if (appointment.serviceEngineerMoveToAll) {
          task[this.resourcesModule] = appointment[this.resourcesModule];
        } else {
          if (task[this.resourcesModule].indexOf(newResource) === -1) {
            task[this.resourcesModule].push(newResource);
          }
        }
      }
      event[this.resourcesModule] = task[this.resourcesModule];
    } else {
      delete task[this.resourcesModule];
    }

    task.warning = false;
    task.changed = true;
    // 
    return this.update(docID, this.convertToObject(task));
  }

  async updateAssignMultiple(assignMultiple) {
    
    const docID = assignMultiple.id;
    if (docID) {
      const appointment = await this.getByID(docID, this.module);
      const user = appointment[this.resourcesModuleField || 'user'];
      const userID = user.ID;
      if (userID && assignMultiple[this.resourcesModule] && assignMultiple[this.resourcesModule].indexOf(userID) === -1) {
        const userIdFromList = assignMultiple[this.resourcesModule][0];
        if (userIdFromList) {
          const userOrObject = await this.getByID(userIdFromList, this.resourcesModule);
          if (userOrObject) {
            assignMultiple[this.resourcesModuleField || 'user'] = {ID: userOrObject.ID, name: userOrObject.name};
          }
        }
      }
    }
    
    this.setDynModuleName('appointments');
    const data = assignMultiple;
    data.ID = assignMultiple.id;
    await this.save(data);
  }

  async dragAndDropFromDayPilot(event) {
    const task = new UpdatePlanedFields();
    const docID = event.ID.split('_||_')[0];
    task.plannedStartDateTime = Timestamp.now();
    task.plannedEndDateTime = Timestamp.now();
    task.status = SelectModel.mandatoryAppointmentStatuses.statusWhenRemovedFromPlanboard;
    task[this.resourcesModule] = [];
    return this.update(docID, this.convertToObject(task));
  }

  async deleteFromCalendar(id: string) {
    const docID = id.split('_||_')[0];
    const userID = id.split('_||_')[1];
    if (userID) {
      const record: any = await this.getByID(docID, this.module);
      record[this.resourcesModule] = record[this.resourcesModule].filter((e) => e !== userID);
      if (record[this.resourcesModule].length) {
        await this.update(docID, record);
      } else {
        return this.delete(docID);
      }
    } else {
      return this.delete(docID);
    }
  }

  update(id, data, callBack = (id: string) => {}) {
    const docID = id.split('_||_')[0];
    return super.update(docID, data, callBack);
  }

}

export default AppointmentModel;
