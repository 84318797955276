
  import { DayPilot } from "daypilot-pro-vue";
  import Orders from "./Orders.vue";
  import Scheduler from "./Scheduler.vue";
  import moment from "moment";
  import PlanBoardModel from "./PlanBoardModel";
  import { mapActions, mapGetters, mapState } from "vuex";
  import db from "../../db";
  import store from "../../store";
  import SelectModel from "../Models/SelectModel";
  import AppointmentModel from "../Models/AppointmentModel";
  import ListValues from "@/components/Mixin/ListValues";
  import {EventBus} from "@/event-bus";
  import CF from "@/components/Helpers/CFunctions";

  export default {
    name: "planboard",
    components: { Orders, Scheduler },
    data() {
      return {
        sortDirect: true,
        dropDownValuesWorkOrders: {},
        dropDownValuesAppointments: {},
        filtersDropDownValuesWorkOrders: {},
        filtersDropDownValuesAppointments: {},
        module: "workOrders",
        doRefreshOrders: 0,
      };
    },
    computed: {
      dynamicModuleWO() {
        return this.dynamicModules ? this.dynamicModules['workOrders'] : {};
      },
      dynamicModuleAP() {
        return this.dynamicModules ? this.dynamicModules['appointments'] : {};
      },
      dynamicModulePB() {
        return this.dynamicModules ? this.dynamicModules['planboard'] : {};
      },
      plannabledModules() {
        const result = []
        for(const moduleName in this.dynamicModules) {
          if(this.dynamicModules[moduleName].plannable) {
            result.push({
              module: moduleName,
              name: this.dynamicModules[moduleName].pluralName || this.dynamicModules[moduleName].name || moduleName
            })
          }
        }
        return result;
      },
      dynFieldsAll() {
        const woFields = this.dynamicModuleWO ? this.dynamicModuleWO.fields : {};
        const apFields = this.dynamicModuleAP ? this.dynamicModuleAP.fields : {};
        const locFields = ListValues.locFields() || [];
        const sharedFields = {}

        for (const fName in woFields) {
          const wo =  { ...woFields[fName], ...{label: woFields[fName].label + ' (' + ListValues.singularTitle('workOrders') + ')' } }
          this.$set(sharedFields, fName + '.workOrders', wo)
        }
        for (const fName in apFields) {
          const ap = { ...apFields[fName], ...{label: apFields[fName].label + ' (' + ListValues.singularTitle('appointments') + ')' } }
          this.$set(sharedFields, fName + '.appointments', ap)
        }
        for (const fName in locFields) {
          const locWo = { ...locFields[fName], ...{label: locFields[fName].label + ' (' + ListValues.singularTitle('workOrders') + ')' } }
          const locAp = { ...locFields[fName], ...{label: locFields[fName].label + ' (' + ListValues.singularTitle('appointments') + ')' } }
          this.$set(sharedFields, fName + '.workOrders', locWo)
          this.$set(sharedFields, fName + '.appointments', locAp)
        }
        return {...woFields, ...sharedFields};
      },
      tasks() {
        const result = PlanBoardModel.tasks(this.appointmentsTasks, {
          fields: this.userViewSettings,
          filters: this.filters,
          dynFields: this.dynFieldsAll,
          resourcesModule: this.planboardSchedulerSettings.resourcesModule
        });
        return result;
      },
      getCustomButtons() {
        return CF.customButtons(this.dynamicModulePB);
      },
      selectedView() {
        return this.planboardViewSettings.find(e => e.ID === this.planboardDefaultViewID) || { ID: '', name: '', users: [] };
      },

      planboardUsersFix() {
        return (this.planboardUsers || []).map(u => {
          if (!u.name) {
            u.name = '';
          }
          return u;
        })
      },

      users() {
        const rawUsers = this.planboardUsersFix || [];
        if (!this.selectedView.ID) return rawUsers;
        const orderedUsers = this.selectedView.users && this.selectedView.users.map(e => rawUsers.find(user => user.ID === e.id)) || [];
        orderedUsers.map(user => {
          if (user) {
            const groupUser = this.selectedView.users.find(e => e.id === user.ID) || {};
            user.planBoardUserGroup = groupUser.group || ''
          }
        })

        return orderedUsers
      },

      noViewUsers(){
        const rawUsers = this.planboardUsersFix || [];
        if (!this.selectedView.ID) return [];
        return rawUsers.filter(user => !this.selectedView.users.find(e => user.ID === e.id)) || [];
      },

      resourses: function () {
        return this.getResourses(this.users, this.selectedView.ID);
      },

      resoursesAll: function () {
        return this.getResourses(this.planboardUsersFix);
      },

      techniciansList: function () {
        return PlanBoardModel.techniciansList(this.resoursesAll);
      },

      userViewSettings: function () {
        const settings = this.userViewSettingsRaw && this.userViewSettingsRaw !== {}
          ? this.userViewSettingsRaw
          : this.defaultViewSettingsRaw;
        return PlanBoardModel.userViewSettings( settings, ListValues.getValidColumns(this.dynFieldsAll));
      },

      orders: function () {
        const appAndWo = PlanBoardModel.orders(this.appointmentsOrders, {filters: this.filters});
        const appointmentsOrders = ListValues.transform(appAndWo, this.dynFieldsAll, true);

        return appointmentsOrders;
      },

      filters: function () {
        return PlanBoardModel.filters(this.filtersRaw, {
          filtersDropDownValuesWorkOrders: this.filtersDropDownValuesWorkOrders,
          filtersDropDownValuesAppointments: this
            .filtersDropDownValuesAppointments,
        });
      },

      counts: () => {
        const counts = {};
        return counts;
      },

      holidays: function () {
        return PlanBoardModel.holidays(this.holidaysRaw);
      },

      absences: function () {
        return [];
      },

      ...mapState("subscribe", ["loading"]),
      ...mapState([
        "listUrgencies",
        "listStatuses",
        "listGroups",
        "listWOTypes",
      ]),
      ...mapState([
        "planUrgencies",
        "planStatuses",
        "planGroups",
        "planWOTypes",
      ]),
      ...mapState(["currentDate", "userViewSettingsRaw", "defaultViewSettingsRaw", "holidaysRaw"]),
      ...mapGetters(["locale", "language"]),
      ...mapGetters("user", { profile: "profile", loggedIn: "loggedIn" }),

      ...mapState("subscribe", ["filtersRaw", "planboardUsers", "planboardPeriod"]),
      ...mapGetters("subscribe", ["appointmentsOrders", "appointmentsTasks"]),
      ...mapState(["dynamicModules"]),
      ...mapState('settings', ["planboardViewSettings", "planboardDefaultViewID",
        "planboardSchedulerSettings", "planboardListSettings"]),
    },
    watch: {},
    methods: {
      ...mapActions([
        "updatedPlanBoardTask",
        "updateUserViewSettings",
      ]),
      ...mapActions(["copyWorkOrder", "sendNotification"]),
      ...mapActions("subscribe", [
        "updatePlanBoardFilters",
        "initPlanBoardFilters",
        "unbindAppointments",
        "unbindOrders",
        "bindAppointments",
        "refreshAppointmentsOrders"
      ]),
      ...mapActions('settings', ["getPlanboardViewSettings", "savePlanboardViewSettings", "deletePlanboardViewSettings",
        "getPlanboardDefaultViewID", "savePlanboardDefaultViewID"]),
      ...mapActions('settings', ["getPlanboardSettings", "savePlanboardSettings"]),

      onPlanbordCustomAction(data) {
        const action = data.action;
        const module = action && action.module ? action.module : 'workOrders';
        const ID = module === 'workOrders' ? data.workOrderID : data.appointmentID;
        CF.doCustomButtonAction(action, module, ID).then().catch();
      },
      getResourses: function (users, viewID = null) {
        const result = PlanBoardModel.resourses(
          users.filter((t) => t && t.availableForPlanning),
          {
            tasks: this.tasks,
            filters: this.filters,
            absences: this.absences,
          },
          viewID
        );
        return result;
      },

      onRefreshWorkOrdersData(woID) {
        this.refreshAppointmentsOrders(woID);
      },

      dragAndDropFromDayPilot() {
        this.doRefreshOrders++;
      },

      async getdropDownValuesWorkOrders() {
        const tempDropDownValues = {};
        const firePath = "tenants/" + store.state.tenantID + "/modules/";
        const result = await db.collection(firePath).doc("workOrders").get();
        const values = result.data() ? result.data().dropDownValues : {};

        for (const val in values) {
          if (Array.isArray(values[val])) {
            tempDropDownValues[val] = values[val].map((v) => {
              return {id: v, name: v};
            });
          }
        }
        this.dropDownValuesWorkOrders = tempDropDownValues;
        this.filtersDropDownValuesWorkOrders = this.dropDownValuesWorkOrders;
      },

      async getdropDownValuesAppointments() {
        const tempDropDownValues = {};
        const firePath = "tenants/" + store.state.tenantID + "/modules/";
        const result = await db.collection(firePath).doc("appointments").get();
        const values = result.data() ? result.data().dropDownValues : {};

        if (SelectModel.appointmentStatusOverWritten) {
          values.status = SelectModel.appointmentStatusValuesPB;
        }

        for (const val in values) {
          if (Array.isArray(values[val])) {
            tempDropDownValues[val] = values[val].map((v) => {
              return {id: v, name: v};
            });
          }
        }

        this.dropDownValuesAppointments = tempDropDownValues;
        this.filtersDropDownValuesAppointments = this.dropDownValuesAppointments;
      },
      receiveTaskShowSettings: function (fields) {
        this.updateUserViewSettings(fields);
      },
      receiveAssignedMultiple: function (assignMultiple) {
        new AppointmentModel().updateAssignMultiple(assignMultiple);
      },
      updateDelayStatus: function () {
        this.appointmentsTasks
          .filter(wo => wo.status !== SelectModel.appointmentStatus.toBePlanned
            && wo.status !== SelectModel.appointmentStatus.done)
          .map((wo) => {
            let task = false;

            const endDate = wo.plannedEndDateTime.toDate();
            let warningTime;

            const estimatedTimeToFixHours = moment
              .duration(
                moment(wo.plannedEndDateTime.toDate()).diff(
                  moment(wo.plannedStartDateTime.toDate())
                )
              )
              .hours();

            if (estimatedTimeToFixHours < 1) {
              warningTime = 0;
            } else if (estimatedTimeToFixHours < 2) {
              warningTime = 5; // [1..2)
            } else if (estimatedTimeToFixHours < 4) {
              warningTime = 10; // [2..4)
            } else if (estimatedTimeToFixHours < 8) {
              warningTime = 20; // [4..8)
            } else if (estimatedTimeToFixHours < 16) {
              warningTime = 30; // [8..16)
            } else {
              warningTime = 60; //  >= 16
            }

            if (wo.status !== SelectModel.appointmentStatus.done) {
              if (
                new DayPilot.Date() >
                new DayPilot.Date(endDate).addMinutes(-warningTime) &&
                !wo.warning &&
                wo.status !== SelectModel.appointmentStatus.delayed
              ) {
                task = {warning: true};
              }
              if (
                new DayPilot.Date() > new DayPilot.Date(endDate) &&
                wo.status !== SelectModel.appointmentStatus.delayed
              ) {
                task = {
                  status: SelectModel.appointmentStatus.delayed,
                  warning: false,
                };
              }
            } else {
              if (wo.warning) {
                task = {warning: false};
              }
            }
            if (task) {
              new AppointmentModel().update(wo.id, task);
            }
          });
      },
      receiveCopyWorkOrder: function (workOrder) {
        this.copyWorkOrder(workOrder);
      },
      receiveSendNotification: function (notification) {
        this.sendNotification(notification);
      },
      onChangeView(viewID){
        const data = {
          userViewID: viewID,
        };
        this.savePlanboardDefaultViewID(data)
          .then(()=>{
            this.getPlanboardDefaultViewID()
              .then(() => {
                this.getPlanboardViewSettings()
            });
        });
      },
      onSaveView(data) {
        this.savePlanboardViewSettings(data)
          .then(viewID => {
            this.onChangeView(viewID)
          })
      },
      onDeleteView(data) {
        this.deletePlanboardViewSettings(data)
          .then(() => {
            this.onChangeView('')
          })
      },
      onSaveSchedulerSettings(settings) {
        const data = {
          schedulerSettings: settings
        }
        this.savePlanboardSettings(data)
          .then(() => {
            this.getPlanboardSettings().then(() => this.bindAppointments())
          })
      },
      onSavePlanboardListSettings(settings) {
        const data = {
          listSettings: settings
        }
        this.savePlanboardSettings(data)
          .then(() => this.getPlanboardSettings())
      },
      onClickForModalForm(e) {
        const className = e.target.className;
        if (className.includes('show-modal-form-default_')) {
          const userID =  className.replace('show-modal-form-default_', '');
          const data = {
            moduleName: this.planboardSchedulerSettings.resourcesModule,
            action: 'edit',
            ID: userID
          }
          EventBus.$emit('show-modal-form-default', data);
        }
      }
    },
    beforeMount() {
      //console.time('beforeMount');
      this.getPlanboardSettings().then(() => {
        this.initPlanBoardFilters();
        document.body.addEventListener('click', this.onClickForModalForm);
      });
      this.getdropDownValuesWorkOrders();
      this.getdropDownValuesAppointments();
    },
    mounted(){
      this.getPlanboardDefaultViewID();
      this.getPlanboardViewSettings();
    },
    beforeDestroy() {
      document.body.removeEventListener('click', this.onClickForModalForm);
      this.unbindAppointments();
      this.unbindOrders();
    },
    timers: {
      //updateDelayStatus: { time: 10 * 1000, autostart: true, repeat: true }
      //updateDelayStatus: { time: 60 * 1000, autostart: true, repeat: true },
    },
  };
