
  export default {
    name: "TaskShowSettings",
    props: {
      userViewSettings: {},
    },
    data() {
      return {
        settings: this.userViewSettings
      };
    },
    methods: {
      applySettings: function () {
        this.$emit("receiveTaskShowSettings", this.settings);
      },
    }
  };
