
  export default {
    name: "FunnelFilter",
    props: {
      id: null,
      filters: {},
      isMapFilter: null,
    },
    data() {
      return {
        listFilters: {},
        dialog: false,
      };
    },
    methods: {
      getListFiltersParam: function () {
        this.$emit("submitFilter", this.listFilters);
      },
      resetFilters: function () {
        this.$emit("resetFilter", this.listFilters);
      },
    },
    computed: {
      isAnyFiltered() {
        if (
          (this.listFilters.status &&
            this.listFilters.status.filter((e) => {
              return e.checked;
            }).length) ||
          (this.listFilters.urgency &&
            this.listFilters.urgency.filter((e) => {
              return e.checked;
            }).length) ||
          (this.listFilters.type &&
            this.listFilters.type.filter((e) => {
              return e.checked;
            }).length)
        ) {
          return true;
        }
        return false;
      },
    },
    watch: {
      filters: function (newFilters) {
        this.listFilters = Object.assign({}, newFilters);
      },
    },
    beforeMount() {
      this.listFilters = this.filters;
    },
  };
