
  import AssignMultiple from "../Parts/AssignMultiple";
  import TaskShowSettings from "../Parts/TaskShowSettings";
  import FunnelFilter from "./Filters/FunnelFilter.vue";
  import PlanBoardModel from "./PlanBoardModel";
  import { DayPilot } from "daypilot-pro-vue";
  import { mapActions } from "vuex";
  import AppointmentModel from "../Models/AppointmentModel";
  import SelectModel from "../Models/SelectModel";
  import { date } from "quasar";
  import ModalForDoubleForm from './ModalForDoubleForm';
  import { Timestamp } from '@firebase/firestore';
  import AbModel from '@/components/Models/AbModel'
  import store from "@/store";
  import ListValues from "@/components/Mixin/ListValues";
  import debounce from "lodash/debounce";
  import sortList from "@/common/helpers/utilities"
  import db from "@/db";
  import Dialog from "@/components/Parts/Dialog.vue";

  export default {
    name: "Scheduler",
    components: {
      AssignMultiple,
      TaskShowSettings,
      FunnelFilter,
      Dialog,
      ModalForDoubleForm
    },
    props: {
      id: {},
      config: {},
      tasks: {},
      orders: {},
      users: {},
      technicians: {},
      techniciansList: {},
      globalHolidays: {},
      absences: {},
      dateStart: {},
      dateEnd: {},
      userViewSettings: {},
      currentDate: {},
      locale: {},
      localeDayPilot: {},
      filters: {},
      planboardPeriod: {},
      filtersDropDownValuesWorkOrders: {},
      filtersDropDownValuesAppointments: {},
      planboardViewSettings: {},
      planboardSchedulerSettings: {},
      selectedViewProps: {},
      noViewUsers: {},
      plannabledModules: {},
      planboardCustomButtons: {}
    },
    data() {
      return {
        resources: [],
        dayPilotQueue: {},
        daypilotScheduler: {},
        activeBtnDay: true,
        activeBtnWeek: false,
        activeBtnMonth: false,
        taskAssignMultiple: {},
        dateStartSheduler: "",
        showModalDoubleForm: false,
        showAssignMultiple: false,
        showTaskShowSettings: false,
        dateInput: this.formatDate(new Date().toISOString().substr(0, 10)),
        exportArea: "viewport",
        showNonBusiness: false,
        businessWeekends: false,
        showDayPilotSetting: false,
        allowMultiMove: false,
        allowMultiResize: false,
        typeOfResourcesFilter: null,
        workOrderRecordID: null,
        appointmentID: null,
        appointmentParams: {},
        viewModel: this.selectedViewProps,
        viewName: '',
        viewShared: false,
        viewLocked: false,
        showSaveAsDialog: false,
        showEditViewDialog: false,
        showViewSettings: false,
        addViewMode: false,
        showModalDeleteView: false,
        noViewUsersModel: [],
        eventHeight: 40,
        viewportDate: null,
        eventHeightOptions: [
          {name: '1 line', value: 21},
          {name: '2 line', value: 40}
          ],
        cellDurationDay: 30,
        cellDurationDayOptions: [
          {name: '10', value: 10},
          {name: '15', value: 15},
          {name: '30', value: 30},
          {name: '60', value: 60}
        ],
        cellDurationWeek: 60,
        cellDurationWeekOptions: [
          {name: '20', value: 20},
          {name: '30', value: 30},
          {name: '60', value: 60},
        ],
        workingHours: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
        workDayStarts: "7",
        workDayEnds: "19",
        newAppointmentWhenWorkDayEnds: false,
        showNewAppointmentWhenWorkDayEndsDialog: false,
        planboardData: null,
        estimatedHoursFromAppointment: false,
        resourcesModule: 'users',
        memViewPort: {start: null, end: null},
        checkRangeChangeTimer: null,
        taskFilterInput: '',
        dateMap: undefined,
        showTotalColumn: true,
        showTotalInMinutes: false,
        availableUsers: [],
        debouncedTaskFilter: debounce(this.taskFilter, 750),
      };
    },

    methods: {
      ...mapActions("subscribe", ["updatePlanBoardFilters", "updatePlanBoardDate"]),
      async getGlobalPlanboardSettings() {
        const doc = await db.collection(`tenants/${store.state.tenantID}/settings`).doc('planboard').get()
        if(doc.exists) {
          const planboardSettings = doc.data()
          this.estimatedHoursFromAppointment = planboardSettings.estimatedHoursFromAppointment ? planboardSettings.estimatedHoursFromAppointment : false
          this.workDayStarts = planboardSettings.workDayStarts ? planboardSettings.workDayStarts : "6"
          this.workDayEnds = planboardSettings.workDayEnds ? planboardSettings.workDayEnds : "20"
          this.showTotalColumn = planboardSettings.showTotalColumn || planboardSettings?.showTotalColumn === false ? planboardSettings.showTotalColumn : true
          this.showTotalInMinutes = planboardSettings.showTotalInMinutes ? planboardSettings.showTotalInMinutes : false
          this.newAppointmentWhenWorkDayEnds = planboardSettings.addNewAppointment ? planboardSettings.addNewAppointment : false
        }
      },
      async saveGlobalPlanboardSettings() {
        await db.collection(`tenants/${store.state.tenantID}/settings`).doc('planboard').set({
          estimatedHoursFromAppointment: this.estimatedHoursFromAppointment,
          workDayStarts: this.workDayStarts,
          workDayEnds: this.workDayEnds,
          showTotalColumn: this.showTotalColumn,
          showTotalInMinutes: this.showTotalInMinutes,
          addNewAppointment: this.newAppointmentWhenWorkDayEnds
        }, {merge: true})
      },
      async openUserSettingDialog() {
        this.showViewSettings = true
        this.availableUsers = await sortList(this.noViewUsers, 'name')
      },
      async checkNewAppointmentWhenWorkdayEnds(data) {
        const model = new AbModel()
        const appointment = await model.getByID(data.appoID, 'appointments');
        let hours = 0;
        if(this.estimatedHoursFromAppointment && appointment && appointment.estimatedTimeOnLocationHour) {
          hours = appointment.estimatedTimeOnLocationHour
        }
        if(!this.estimatedHoursFromAppointment && data.order.wo) {
          hours = data.order.wo.estimatedTimeOnLocationHour 
        }
        const start = data.start.toDateLocal();
        const startingHour = start.getHours()
        const endTime = startingHour + hours
        if(endTime > Number(this.workDayEnds)) {
          return true
        }
        return false
      },
      async appointmentAddMove(args) {
        this.planboardData = args
        const data = args.e.data;
        if (!data.id) {
          data.order = this.getOrderByID(data.orderID);
          if(this.newAppointmentWhenWorkDayEnds) {
            this.showNewAppointmentWhenWorkDayEndsDialog = await this.checkNewAppointmentWhenWorkdayEnds(data)
          } 
          if(!this.showNewAppointmentWhenWorkDayEndsDialog) {
            const model = new AppointmentModel();
            model.createFromDayPilot(data, this.estimatedHoursFromAppointment);
          }
        } else {

          args.multimove.forEach((a) =>
          {

            const res = new AppointmentModel().updateFromDayPilot(a, a.resource);
            if (res === false) {
              return false;
            }
          }
          );
        }
      },
      addOneAppointment() {
        const data = this.planboardData.e.data
        data.order = this.getOrderByID(data.orderID);
        const model = new AppointmentModel();
        model.createFromDayPilot(data, this.estimatedHoursFromAppointment);
      },
      createMultipleAppointments() {
        const data = this.planboardData.e.data
        data.order = this.getOrderByID(data.orderID);
        const model = new AppointmentModel();
        const schedulerSettings = {
          useAppointmentHours: this.estimatedHoursFromAppointment,
          workDayStarts: this.workDayStarts,
          workDayEnds: this.workDayEnds,
          businessWeekends: this.businessWeekends,
          showNonBusiness: this.showNonBusiness
        }
        model.createMultipleAppointmentsFromDayPilot(data, schedulerSettings);
      },
      getFilterType(filter) {
        let ftype = this.$t('table.headers.filterPrivate');
        if (filter.shared) {
          ftype = filter.authorName ? this.$t('table.headers.filterSharedByUser') + ' ' + filter.authorName : this.$t('table.headers.filterShared');
        }
        return ftype;
      },
      async addNewAppointment(args) {
        const model = new AbModel();
        const userOrObject = await model.getByID(args.resource, this.resourcesModule);

        this.appointmentParams = {
          [this.resourcesModule]: [userOrObject.ID],
          ['__' + this.resourcesModule]: [{ID: userOrObject.ID, name: userOrObject.name}],
          plannedStartDateTime: this.toTimestampValue(args.start.toDateLocal()),
          plannedEndDateTime: this.toTimestampValue(args.end.toDateLocal()),
          status: SelectModel.mandatoryAppointmentStatuses.statusWhenReleasedInPlanboard,
        };

        const resourcesModuleField = store.state.allModules[this.resourcesModule].singularName;

        if (!resourcesModuleField) {
          console.error('No singular name for the module - ' + this.resourcesModule);
        }
        this.appointmentParams[this.resourcesModuleField || 'user'] = {ID: userOrObject.ID, name: userOrObject.name};

        //

        this.appointmentID = null;
        this.workOrderRecordID = null;
        this.showModalDoubleForm = true;
      },

      toTimestampValue(val) {
        return Timestamp.fromMillis(Date.parse(val));
      },

      resetShowModalDoubleForm()  {
        this.showModalDoubleForm = false;
        this.appointmentID = null;
        this.workOrderRecordID = null;
        this.appointmentParams = {};
      },

      appointmentResize(args) {
        args.multiresize.forEach((a) =>
          new AppointmentModel().updateFromDayPilot(a)
        );
      },
      getOrderByID(id) {
        return this.orders.find((wo) => wo.ID === id);
      },
      getUserByID(id) {
        return this.users.find((us) => us.id === id);
      },
      submitPlanDateFilter: function (period) {

        this.updatePlanBoardDate(period);
      },
      submitPlanFilter: function (filters) {
        this.updatePlanBoardFilters({ tasks: { filters } });
      },
      resetPlanFilter: function () {
        this.updatePlanBoardFilters({
          tasks: PlanBoardModel.getEmptyFilter({
            filtersDropDownValuesWorkOrders: this
              .filtersDropDownValuesWorkOrders,
            filtersDropDownValuesAppointments: this
              .filtersDropDownValuesAppointments,
          }),
        });
      },
      formatDate: function (date) {
        if (!date) return null;

        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      },
      dayBack: function () {
        this.changeDate(this.dateStartSheduler.addDays(-1));
        this.debouncedTaskFilter(this.taskFilterInput)
      },
      periodBack: function () {
        if(this.activeBtnWeek) {
          const date = this.dateStartSheduler
          const monday = new DayPilot.Date(date).firstDayOfWeek("nl-nl")
          this.dateStartSheduler = monday
          this.changeDate(this.dateStartSheduler.addDays(-this.schedulerDays));
          this.debouncedTaskFilter(this.taskFilterInput)
        }
        if(!this.activeBtnWeek) {
          this.changeDate(this.dateStartSheduler.addDays(-this.schedulerDays));
          this.debouncedTaskFilter(this.taskFilterInput)
        }
      },
      dayStart: function () {
        this.dateStartSheduler = DayPilot.Date.parse(
          this.dateInput,
          "dd-MM-yyyy"
        );
        this.changeDate(this.dateStartSheduler);
      },
      dayAdd: function () {
        this.changeDate(this.dateStartSheduler.addDays(1));
        this.debouncedTaskFilter(this.taskFilterInput)
      },
      periodAdd: function () {
        if(this.activeBtnWeek) {
          const date = this.dateStartSheduler
          const monday = new DayPilot.Date(date).firstDayOfWeek("nl-nl")
          this.dateStartSheduler = monday
          this.changeDate(this.dateStartSheduler.addDays(this.schedulerDays));
          this.debouncedTaskFilter(this.taskFilterInput)
        }
        if(!this.activeBtnWeek) {
          this.changeDate(this.dateStartSheduler.addDays(this.schedulerDays));
          this.debouncedTaskFilter(this.taskFilterInput)
        }
      },
      changeDate(date) {
        this.dateStartSheduler = date
        this.scheduler.startDate = date;
        this.schedulerScrollTo();
        this.checkRangeChange();
      },
      schedulerScrollTo(date){
        //console.time()
        this.scheduler.update()
        this.dateInput = this.scheduler.startDate.toString("dd-MM-yyyy");
        setTimeout(()=> {
          if (typeof this.scheduler.scrollTo === 'function') {
            if(date) {
              this.scheduler.scrollTo(date);
            } else {
              this.scheduler.scrollTo(this.dateStartSheduler);
            }
          }
        }, 1000)
      },
      isSchedulerViewPortInPeriod(viewPort){
        const viewPortStart = date.extractDate(viewPort.start.addDays(-5).toString("dd-MM-yyyy HH:mm"), "DD-MM-YYYY HH:mm");
        const viewPortEnd = date.extractDate(viewPort.end.addDays(5).toString("dd-MM-yyyy HH:mm"), "DD-MM-YYYY HH:mm");
        const periodStart = date.extractDate(this.planboardPeriod.start, "DD-MM-YYYY HH:mm");
        const periodEnd = date.extractDate(this.planboardPeriod.end, "DD-MM-YYYY HH:mm");

        const viewPortStartOK = date.isBetweenDates(viewPortStart, periodStart, periodEnd,{ inclusiveFrom: true, inclusiveTo: true });
        const viewPortEndOK = date.isBetweenDates(viewPortEnd, periodStart, periodEnd,{ inclusiveFrom: true, inclusiveTo: true });
        return viewPortStartOK && viewPortEndOK
      },
      setPeriodFromScheduler() {
        const viewPort = this.scheduler.getViewPort()
        if (!viewPort || !viewPort.start || !viewPort.end) {

        }
        let dateObj = {}
        if ((this.activeBtnDay || this.activeBtnWeek) && !this.isSchedulerViewPortInPeriod(viewPort)){
          dateObj = {
            start: viewPort.start.addDays(-15).toString("dd-MM-yyyy HH:mm"),
            end: viewPort.end.addDays(15).toString("dd-MM-yyyy HH:mm"),
          }
          this.submitPlanDateFilter(dateObj)
        }
        if (this.activeBtnMonth && !this.isSchedulerViewPortInPeriod(viewPort)){
          dateObj = {
            start: viewPort.start.addDays(-7).toString("dd-MM-yyyy HH:mm"),
            end: viewPort.end.addDays(7).toString("dd-MM-yyyy HH:mm"),
          }
          this.submitPlanDateFilter(dateObj)
        }
      },
      checkRangeChange: function () {
        const viewPort = this.scheduler.getViewPort()
        if (this.memViewPort.start !== viewPort.start || this.memViewPort.end !== viewPort.end){
          this.setPeriodFromScheduler()
        }
        this.memViewPort.start = viewPort.start
        this.memViewPort.end = viewPort.end
      },
      loadLinks: function () {
        /*
        const uniqContainers = [];
        let links = [];
        const containers = {};
        this.tasks.forEach((t) => {
          if (t && !uniqContainers.includes(t.container)) {
            uniqContainers.push(t.container);
            containers[t.container] = [];
          }
        });
        uniqContainers.forEach((u) => {
          const container = this.tasks
            .filter((t) => t && t.container === u)
            .map((c) => (c = c.id));
          containers[u].push(...container);
        });

        for (const c of Object.values(containers)) {
          for (let i = 1; i < c.length; i++) {
            if (c.length > 1) links.push({ from: c[i - 1], to: c[i] , style: 'solid', color: "pink"});
          }
        }
        */
        const links = []; // disable the event links as they don't make much sense at this moment, we need to rethink this functionality.
        return links;
      },
      loadResources: debounce (function () {
        this.scheduler.update({ resources: this.technicians, events: this.tasks });
        //console.timeEnd('loadResources');
      }, 750),
      currentSeparatorsUpdateByTimer: function () {
        this.scheduler.separators = [
          {
            color: "#415F66",
            location: new DayPilot.Date(),
            layer: "UnderEvents",
            width: 1,
          },
        ];
        this.filterAppointments(this.taskFilterInput)
        this.scheduler.update();
      },

      dayScheduler() {
        this.activeBtnDay = true;
        this.activeBtnWeek = false;
        this.activeBtnMonth = false;
        Object.assign(this.daypilotScheduler, {
          cellWidthSpec: "Auto",
          cellWidthMin: 40,
          cellDuration: this.cellDurationDay,
          timeHeaders: [
            { groupBy: "Day", format: "dddd dd MMMM yyyy" },
            { groupBy: "Hour", format: "H" },
          ],
          scale: "CellDuration",
          days: 3,
          startDate: this.dateStartSheduler,
          businessBeginsHour: Number(this.workDayStarts),
          businessEndsHour: Number(this.workDayEnds),
          // eslint-disable-next-line
          //scrollTo: new DayPilot.Date.today(),
          infiniteScrollingEnabled: false,
          //infiniteScrollingMargin: 1,
          //infiniteScrollingStepDays: 1,
        });

        this.scheduler.rowHeaderColumns = [{ title: this.$t("planBoard.headers.name"), width: 200 }]
        if(this.showTotalColumn){
          this.scheduler.rowHeaderColumns.push({ name: this.$t("productLines.headers.total"), width: 55 })
        }
        this.loadResources();
        this.daypilotScheduler.showCurrentTime = true;
        this.schedulerScrollTo();
      },
      weekScheduler() {
        this.activeBtnDay = false;
        this.activeBtnWeek = true;
        this.activeBtnMonth = false;
        const date = DayPilot.Date.today();
        const monday = new DayPilot.Date(date).firstDayOfWeek("nl-nl")
        // this.dateStartSheduler = monday
        Object.assign(this.daypilotScheduler, {
          //cellWidthSpec: "Auto",
          cellWidthSpec: "Fixed",
          cellWidth: 20,
          cellDuration: this.cellDurationWeek,
          timeHeaders: [
            { groupBy: "Month" },
            { groupBy: "Week" },
            { groupBy: "Day", format: "dddd dd MMMM yyyy" },
            { groupBy: "Hour", format: "H" }
            //{groupBy: "Cell", format: "dddd"},
            //{groupBy: "Cell", format: "d MMMM"}
          ],
          scale: "CellDuration",
          days: 28,
          startDate: this.dateStartSheduler,
          businessBeginsHour: Number(this.workDayStarts),
          businessEndsHour: Number(this.workDayEnds),
          //scrollTo: new DayPilot.Date.today(),
          infiniteScrollingEnabled: true,
          infiniteScrollingMargin: 50,
          infiniteScrollingStepDays: 4,
        });
        this.scheduler.rowHeaderColumns = [{ title: this.$t("planBoard.headers.name"), width: 200 }]
        if(this.showTotalColumn){
          this.scheduler.rowHeaderColumns.push({ name: this.$t("productLines.headers.total"), width: 55 })
        }
        this.loadResources();
        this.schedulerScrollTo(monday);
      },
      monthScheduler() {
        this.activeBtnDay = false;
        this.activeBtnWeek = false;
        this.activeBtnMonth = true;

        Object.assign(this.daypilotScheduler, {
          //cellWidthSpec: "Auto",
          cellWidthSpec: "Fixed",
          cellWidth: 60,
          timeHeaders: [
            { groupBy: "Month" },
            { groupBy: "Week" },
            { groupBy: "Cell", format: "ddd" },
            { groupBy: "Cell", format: "d MMM" },
          ],
          scale: "Day",
          // eslint-disable-next-line
          days: 90, //DayPilot.Date.today().daysInMonth(),
          startDate: this.dateStartSheduler,
          // eslint-disable-next-line
          //scrollTo: new DayPilot.Date.today(),
          infiniteScrollingEnabled: true,
          infiniteScrollingMargin: 50,
          infiniteScrollingStepDays: 6,
        });
        this.scheduler.rowHeaderColumns = [{ title: this.$t("planBoard.headers.name"), width: 200 }]
        this.loadResources();
        this.schedulerScrollTo();
      },
      receiveTaskShowSettings: function (fields) {
        this.$emit("receiveTaskShowSettings", fields);
      },
      receiveAssignedMultiple: function (assignMultiple) {
        this.$emit("receiveAssignedMultiple", assignMultiple);
        this.showAssignMultiple = false;
      },
      receiveCancelAssignedMultiple: function () {
        this.showAssignMultiple = false;
      },
      applyResourcesSelect: function () {
        const selectedRows = this.scheduler.rows.selection.get();
        const selectedRowsName = [];
        selectedRows.forEach((r) => selectedRowsName.push(r.name));
        this.typeOfResourcesFilter = "select";
        this.scheduler.rows.filter(selectedRowsName);
        if (this.viewModel.ID) this.onSaveView()
      },
      clearResourcesSelect: function () {
        if (this.typeOfResourcesFilter === "select") {
          this.scheduler.rows.filter([]);
          this.scheduler.rows.selection.clear();
        }
      },
      resourceFilter: function (query) {
        this.typeOfResourcesFilter = "filter";
        this.scheduler.rows.filter(query); // see dp.onRowFilter below
        this.scheduler.rows.selection.clear();
      },
      async taskFilter(query) {
        await this.filterAppointments(query)
      },
      filterAppointments: async function (query){
        if (query) {
          for(const task of this.tasks) {
            if(task.lightColor == '#f8b13390') {
              task.lightColor = task.oldColor
              // this.scheduler.events.update(task)
            }
          }
          this.scheduler.multiselect.clear();
          this.scheduler.update()
          const finded = this.scheduler.events.findAll(function (e) {
            const match = e.text().toUpperCase().indexOf(query.toUpperCase()) > -1;
            return match;
          });
          this.scheduler.multiselect.clear();
          if (finded) {
            this.scheduler.multiselect.add(finded);
            this.scheduler.events.scrollIntoView(finded);
            for(const appointment of finded) {
              appointment.data.oldColor = appointment.data.lightColor
              appointment.data.lightColor = '#f8b13390'
              this.scheduler.events.update(appointment)
            }
          }
        } else {
          for(const task of this.tasks) {
            if(task.lightColor == '#f8b13390') {
              task.lightColor = task.oldColor
            }
          }
          this.scheduler.multiselect.clear();
          this.scheduler.update()
        }
      },
      exportToJpg(ev) {
        ev.preventDefault();
        this.scheduler.exportAs("jpeg", { area: this.exportArea }).download();
      },
      getStatusLabel(statusName) {
        const translationKey = `appointments.dropDowns.status.${statusName}`
        if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
          return store.state.fieldTranslations[translationKey]
        }
        return statusName
      },
      availableStatuses() {
        const app = this
        const statuses = SelectModel.appointmentStatusValuesPB.map(sname => {
          return {
            text: app.getStatusLabel(sname),
            onClick: async function (args) {
              await app.updateStatusChangedThroughPlanboardCounter()
              const e = args.source;
              const data = {
                status: sname,
              };
              new AppointmentModel().update(e.data.id, data);
            },
          }
        })

        const statusLabels = []
        for (const test in statuses) {
          statusLabels.push(statuses[test].text);
        }
        if (!statusLabels.includes("Inplannen") && !statusLabels.includes("To be planned")) {
          statuses.push({
            text: this.$t("planBoard.buttons.toBePlanned"),
            onClick: function (args) {
              const e = args.source;
              const data = {
                status: SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned,
              };
              new AppointmentModel().update(e.data.id, data);
            },
          });
        }
        return statuses;
      },
      async updateStatusChangedThroughPlanboardCounter() {
        const user = await db.doc(`tenants/${store.state.tenantID}/counters/statusChangeThroughPlanboardCounter`).get()
        const data = user.data()
        if(user.exists && Object.keys(data).includes(store.state.currentUser.name)) {
          let value = data[store.state.currentUser.name]
          value = value += 1
          await db.doc(`tenants/${store.state.tenantID}/counters/statusChangeThroughPlanboardCounter`).set({
            [store.state.currentUser.name]: value
          }, {merge: true})
        } else {
          await db.doc(`tenants/${store.state.tenantID}/counters/statusChangeThroughPlanboardCounter`).set({
            [store.state.currentUser.name]: 1
          }, {merge: true})
        }
      },
      initContextMenu() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const app = this;
        return new DayPilot.Menu({
          theme: "BouwBit_Scheduler_Menu",
          items: [
            {
              text: this.$t("planBoard.buttons.select"),
              onclick: function () {
                app.multiselect.add(this.source);
              },
            },
            {
              text: this.$t("common.buttons.delete"),
              cssClass: 'text-red',
              onClick: function (args) {
                const e = args.source;
                new AppointmentModel().deleteFromCalendar(e.data.id);
              },
            },
            {
              text: "-",
            },
            {
              text: this.$t("planBoard.buttons.assignMore"),
              onClick: function (args) {
                const e = args.source;
                app.taskAssignMultiple = e.data;
                app.showAssignMultiple = true;
              },
            },
            {
              text: "-",
            },
            {
              text: this.$t("planBoard.buttons.settings"),
              onClick: function () {
                app.showTaskShowSettings = true;
              },
            },
            {
              text: "-",
            },
            {
              text: this.$t("planBoard.buttons.changeStatus"),
              items: this.availableStatuses(),
              items2: [
                {
                  text: this.$t("planBoard.buttons.toBePlanned"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.mandatoryAppointmentStatuses.statusWhenToBePlanned,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },
                {
                  text: this.$t("planBoard.buttons.dragged"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.mandatoryAppointmentStatuses.statusWhenRemovedFromPlanboard,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },
                {
                  text: this.$t("planBoard.buttons.toBeConfirmed"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.appointmentStatus.toBeConfirmed,
                      delayed: false,
                      warning: false,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },
                {
                  text: this.$t("planBoard.buttons.planned"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    new AppointmentModel().update(e.data.id, {
                      status: SelectModel.mandatoryAppointmentStatuses.statusWhenReleasedInPlanboard,
                    });
                  },
                },
                {
                  text: this.$t("planBoard.buttons.traveling"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    new AppointmentModel().update(e.data.id, {
                      status: SelectModel.appointmentStatus.traveling,
                    });
                  },
                },
                {
                  text: this.$t("planBoard.buttons.inExecution"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    new AppointmentModel().update(e.data.id, {
                      status: SelectModel.appointmentStatus.inExecution,
                    });
                  },
                },
                {
                  text: this.$t("planBoard.buttons.delayed"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    new AppointmentModel().update(e.data.id, {
                      status: SelectModel.appointmentStatus.delayed,
                    });
                  },
                },
                {
                  text: this.$t("planBoard.buttons.done"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.appointmentStatus.done,
                      delayed: false,
                      warning: false,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },
                {
                  text: this.$t("planBoard.buttons.freeToPlaned"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.appointmentStatus.freeToPlaned,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },
                {
                  text: this.$t("planBoard.buttons.plannedWithoutAppointment"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.appointmentStatus.plannedWithoutAppointment,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },

                {
                  text: this.$t("planBoard.buttons.notExecuted"),
                  onClick: function (args) {
                    app.updateStatusChangedThroughPlanboardCounter()
                    const e = args.source;
                    const data = {
                      status: SelectModel.appointmentStatus.notExecuted,
                    };
                    new AppointmentModel().update(e.data.id, data);
                  },
                },
              ],
            },
            {
              text: "-",
            },
            {
              text: this.$t("planBoard.buttons.copyAppointment"),
              onClick: function (args) {
                const appointment = Object.assign({}, args.source.data);
                new AppointmentModel().copyDayPilot(appointment);
              },
            },
            {
              text: this.$t("planBoard.buttons.openAppointment"),
              onClick: function (args) {
                app.showModalDoubleForm = true;
                app.appointmentID = args.source.data.ID;
                app.workOrderRecordID = args.source.data.wo.ID;

              },
            },
            {
              text: this.$t("planBoard.buttons.sendNotification"),
              onClick: function (args) {
                const notification = {
                  module: { ID: "workOrders", name: "workOrders" },
                  relatedTo: {
                    ID: args.source.data.wo.id,
                    name: args.source.data.wo.name
                      ? args.source.data.wo.name
                      : "no value",
                  },
                  user: args.source.data.resource,
                  title: "Plannings wijziging",
                  message:
                    app.$t("planBoard.headers.appointment") +
                    " " +
                    args.source.data.wo.number +
                    " is gewijzigd, check aub",
                };
                app.$emit("receiveSendNotification", notification);
              },
            },
            {
              text: this.$t("planBoard.buttons.addComment"),
              onClick: function (arg) {
                const currentCommentValue = arg.source.data.remarks
                  ? arg.source.data.remarks
                  : "";
                // eslint-disable-next-line
                DayPilot.Modal.prompt(`${currentCommentValue}`, "", {
                  theme: "modal_default",
                })
                  //DayPilot.Modal.prompt('Type a comment...','',{theme: "modal_default"})
                  .then((modal) => {
                    if (typeof modal.result !== "undefined") {
                      new AppointmentModel().update(arg.source.data.id, {
                        remarks: modal.result,
                      });
                    }
                  });
              },
            },
            ... this.getCustomButtons(),
          ],
        });
      },
      initContextMenuResource() {
        const app = this;
        const groupName =  this.$t('planBoard.headers.groupName');
        const resourceWillBeDeleted =  this.$t('planBoard.headers.resourceWillBeDeleted');
        const commonBtnOk = this.$t("common.buttons.ok");
        const commonBtnCancel = this.$t("common.buttons.cancel")
        return new DayPilot.Menu({
          items: /*app.viewModel.ID ? */ [
            {
              text: this.$t('planBoard.buttons.addResourceToGroup'),
              onClick: function(rowArgs) {
                if (!app.viewModel.ID) {
                  alert('Select or create View first')
                  return
                }
                const row = rowArgs.source;

                if (!row.data.user) {
                  return
                }
                DayPilot.Modal.prompt(`${groupName} ${row.data.groupName || ''}`, { okText: `${commonBtnOk}`, cancelText: `${commonBtnCancel}` }).then(function(args) {
                  if (!args.result) {
                    return;
                  }
                  const currentRow = app.scheduler.rowlist.find(r => r.resource.id === row.data.id);
                  currentRow.resource.user.planBoardUserGroup = args.result ;
                  app.onSaveView();
                });
              }
            },
            {
              text: this.$t('planBoard.buttons.deleteResource'),
              onClick: function(rowArgs) {
                if (!app.viewModel.ID) {
                  alert('Select or create View first')
                  return
                }
                const row = rowArgs.source;

                if (!row.data.user) {
                  return
                }
                DayPilot.Modal.confirm(`"${row.data.user.name}" ${resourceWillBeDeleted}`, { okText: `${commonBtnOk}`, cancelText: `${commonBtnCancel}` }).then(function(args) {
                  if (!args.result) {
                    return;
                  }
                  const currentRow = app.scheduler.rowlist.find(r => r.resource.id === row.data.id);
                  currentRow.resource.user.deleted = true ;
                  app.onSaveView();
                });
              }
            }
          ] /*: [{text: "To assign user to view group select or create view  first"}]*/
        })
      },
     /* initDropTarget() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const app = this;
        const drop = document.getElementsByClassName("j-planboard-orders-container")[0];
        drop.ondragover = function (ev) {
          ev.preventDefault();
          ev.dataTransfer.dropEffect = "copy";
        };
        drop.onmouseup = function(ev) {

        };
        drop.ondrop = function (ev) {
          const data = ev.dataTransfer.getData("daypilot/external-item");
          const evt = JSON.parse(data);

          new AppointmentModel().dragAndDropFromDayPilot(evt);
          app.$emit('dragAndDropFromDayPilot');
        };
      },*/
      getShowedResources() {
        const showedResources = [];
        this.scheduler.rowlist.forEach(e => {
          if (!e.hidden && e.resource && e.resource.id && e.resource.user && !e.resource.user.deleted){
            showedResources.push(
              {
                group: e.resource && e.resource.user && e.resource.user.planBoardUserGroup || '-',
                id: e.resource.id
              })
          }
          if (e.resource && e.resource.user && e.resource.user.planBoardUserGroup) e.resource.user.planBoardUserGroup = '-';
          if (e.resource && e.resource.user && e.resource.user.deleted) e.resource.user.deleted = false;
        });

        if (this.noViewUsersModel.length) {
          this.noViewUsersModel.forEach(u => showedResources.push({group: '-', id: u.id}))
        }

        return showedResources;
      },
      getOnCreateViewResourses(){
        const flatResources = [];
        this.scheduler.rowlist.forEach(e => {
          if (!e.hidden && e.resource && e.resource.id && e.resource.user){
            flatResources.push(
              {
                group: '-',
                //group: e.resource && e.resource.user && e.resource.user.planBoardUserGroup || '-',
                id: e.resource.id
              })
          }
          if (e.resource && e.resource.user && e.resource.user.planBoardUserGroup) e.resource.user.planBoardUserGroup = '-';
          if (e.resource && e.resource.user && e.resource.user.deleted) e.resource.user.deleted = false;
        });

        return flatResources;
      },
      onCreateView(){
        if (this.viewName) {
          const data = {
            id: null,
            view: {
              name: this.viewName,
              shared: this.viewShared,
              locked: this.viewShared ? this.viewLocked : false,
              author: store.state.userID,
              authorName: ListValues.getCurrentUserName(),
              users: this.getOnCreateViewResourses(),
              period: this.getSchedulerPeriod()
            },
          };
          this.clearResourcesSelect();
          this.resourceFilter('')
          this.$emit('onSaveView', data);
          this.showViewSettings = false;
          this.viewModel = { ID: '', name: '', users: [] };
          this.viewName = '';
          this.viewShared = false;
          this.viewLocked = false;
        }
      },
      onSaveView(){
        if (this.viewModel.ID) {
          const data = {
            id: this.viewModel.ID,
            view: {
              name: this.viewModel.name,
              shared: this.viewModel.shared,
              locked: this.viewModel.locked ? true : false,
              author: this.viewModel.author,
              users: this.getShowedResources(),
              period: this.getSchedulerPeriod()
            },
          };
          this.clearResourcesSelect();
          this.resourceFilter('')
          this.$emit('onSaveView', data);
          this.viewName = '';
          this.noViewUsersModel = []
        } else alert ('Select or create View first')
      },
      onDeleteView(){
        const data = {
          id: this.viewModel.ID,
          view: {
            shared: this.viewModel.shared,
            locked: this.viewModel.locked,
            author: this.viewModel.author,
          },
        };
        this.clearResourcesSelect();
        this.resourceFilter('')
        this.$emit('onDeleteView', data);
        this.viewModel = { ID: '', name: '', users: [] };
      },
      onShowDefaultView(){
        this.viewModel = { ID: '', name: '', users: [] };
        this.onChangeView()
      },
      onChangeView(){
        this.clearResourcesSelect();
        this.resourceFilter('')
        this.$emit('onChangeView', this.viewModel.ID);
      },
      getSchedulerPeriod () {
        let period = 'day';
        if (this.activeBtnDay) {
          period = 'day'
        } else if (this.activeBtnWeek) {
          period = 'week'
        } else if (this.activeBtnMonth) {
          period = 'month'
        }
        return period
      },
      getCustomButtons() {
        const res = [];
        const buttons = this.planboardCustomButtons ? Object.values(this.planboardCustomButtons) : [];
        if (buttons.length) {
          res.push({ text: "-"});
        }
        buttons.sort((a, b) => {
          return a.order - b.order;
        }).forEach(b => {
          res.push({
            text: b.label,
            onClick: (e) => {
              const data = {
                action: b,
                appointmentID: e.source.data.ID,
                workOrderID: e.source.data.wo ? e.source.data.wo.ID : ''
              }
              this.$emit('onPlanbordCustomAction', data);
            }
          })
        })
        return res;
      },
      getTotalSubHeaders(args) {
        let totalRowHours = 0
        if(this.activeBtnDay) {
          let eventDurations = 0
          for(const row of args.row.children()) {
            const hours = "T22:00:00Z"
            const date = this.viewportDate.value.split("T")[0]
            const endDate = new Date(date+hours)
            const startDate = new Date(this.viewportDate.addDays(-1).value.split("T")[0]+hours)
            const events = row.events.forRange(startDate, endDate)
            for(const event of events) {
              if(this.showTotalInMinutes) {
                eventDurations += event.duration().totalMinutes()
              } else {
                eventDurations += event.duration().totalHours()
              }
            }
            totalRowHours = eventDurations
          }
        } else if (this.activeBtnWeek) {
          let eventDurations = 0
          for(const row of args.row.children()) {
            const addDays = this.showNonBusiness ? 8 : 6
            const date = this.viewportDate.value.split("T")[0]
            const today = new Date(date+"T22:00:00Z")
            const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
            const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + addDays));
            const events = row.events.forRange(firstDay, lastDay)
            for(const event of events) {
              if(this.showTotalInMinutes) {
                eventDurations += event.duration().totalMinutes()
              } else {
                eventDurations += event.duration().totalHours()
              }
            }
            totalRowHours = eventDurations
          }
        }
        return totalRowHours.toFixed(2)
      },
      getTotalDuration(args) {
        let totalRowHours = 0
        if(this.activeBtnDay) {
          const hours = "T22:00:00Z"
          const date = this.viewportDate.value.split("T")[0]
          const endDate = new Date(date+hours)
          const startDate = new Date(this.viewportDate.addDays(-1).value.split("T")[0]+hours)
          const events = args.row.events.forRange(startDate, endDate)
          let eventDurations = 0
          for(const event of events) {
            if(this.showTotalInMinutes) {
              eventDurations += event.duration().totalMinutes()
            } else {
              eventDurations += event.duration().totalHours()
            }
          }
          totalRowHours = eventDurations
        }
        if(this.activeBtnWeek) {
          const addDays = this.showNonBusiness ? 8 : 6
          const date = this.viewportDate.value.split("T")[0]
          const today = new Date(date+"T22:00:00Z")
          const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
          const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + addDays));
          const events = args.row.events.forRange(firstDay, lastDay)
          let eventDurations = 0
          for(const event of events) {
            if(this.showTotalInMinutes) {
              eventDurations += event.duration().totalMinutes()
            } else {
              eventDurations += event.duration().totalHours()
            }
          }
          totalRowHours = eventDurations
        }
        return parseFloat(totalRowHours.toFixed(2))
      },
      saveSchedulerSettings(){
        const settings = {
          showNonBusiness: this.showNonBusiness,
          businessWeekends: this.businessWeekends,
          eventHeight: this.eventHeight,
          resourcesModule: this.resourcesModule,
          cellDurationDay: this.cellDurationDay,
          addNewAppointment: this.newAppointmentWhenWorkDayEnds,
          cellDurationWeek: this.cellDurationWeek
        }
        this.$emit('onSaveSchedulerSettings', settings);
      }
    },
    timers: {
      currentSeparatorsUpdateByTimer: {
        time: 60 * 1000,
        autostart: true,
        repeat: true,
      },
    },
    computed: {
      scheduler() {
        return this.daypilotScheduler;
      },
      todayWeekDay() {
        let today = DayPilot.Date.today().toString("dddd d", "nl-NL");
        today = today.charAt(0).toUpperCase() + today.slice(1); // First letter capital.
        return today;
      },
      todayMonthYear() {
        let today = DayPilot.Date.today().toString("MMMM yyyy", "nl-NL");
        today = today.charAt(0).toUpperCase() + today.slice(1); // First letter capital.
        return today;
      },

      weekNumber() {
        return DayPilot.Date.today().weekNumberISO();
      },
      schedulerDays(){
        let result
        if(this.activeBtnDay) result = 1;
        if(this.activeBtnWeek) result = 7;
        if(this.activeBtnMonth) result = 31;
        return result;
      }
    },
    watch: {
      taskFilterInput() {
        if(this.taskFilterInput == '') {
          this.debouncedTaskFilter(this.taskFilterInput);
        }
      },
      technicians () {
        this.loadResources();
      },
      locale () {
        if (this.scheduler) {
          this.scheduler.locale = this.locale;
          this.daypilotScheduler.contextMenu = this.initContextMenu();
          this.scheduler.update();
        }
      },
      dayPilotDateStart() {
        // eslint-disable-next-line
        var start = DayPilot.Date.parse(this.dateStart, "yyyy-MM-dd");
        // eslint-disable-next-line
        var end = DayPilot.Date.parse(this.dateEnd, "yyyy-MM-dd");
        if (start && end) {
          this.dateStartSheduler = start;
          this.scheduler.startDate = this.dateStartSheduler;
          // eslint-disable-next-line
          this.scheduler.days = DayPilot.DateUtil.daysDiff(start, end);
          this.scheduler.update();

          this.dateInput = this.dateStart;
        }
      },
      dayPilotDateEnd() {
        // eslint-disable-next-line
        var start = DayPilot.Date.parse(this.dateStart, "yyyy-MM-dd");
        // eslint-disable-next-line
        var end = DayPilot.Date.parse(this.dateEnd, "yyyy-MM-dd");
        if (start && end) {
          this.dateStartSheduler = start;
          this.scheduler.startDate = this.dateStartSheduler;
          // eslint-disable-next-line
          this.scheduler.days = DayPilot.DateUtil.daysDiff(start, end);
          this.scheduler.update();
        }
      },
      planboardSchedulerSettings () {
        this.showNonBusiness = this.planboardSchedulerSettings.showNonBusiness || false
        this.businessWeekends = this.planboardSchedulerSettings.businessWeekends || false
        this.eventHeight = this.planboardSchedulerSettings.eventHeight || 40
        this.cellDurationDay = this.planboardSchedulerSettings.cellDurationDay || 30
        this.cellDurationWeek = this.planboardSchedulerSettings.cellDurationWeek || 60
        this.resourcesModule = this.planboardSchedulerSettings.resourcesModule || 'users'
        this.newAppointmentWhenWorkDayEnds = this.planboardSchedulerSettings.addNewAppointment || false
      },
      showNonBusiness: function () {
        this.scheduler.showNonBusiness = this.showNonBusiness;
        this.scheduler.update();
      },
      businessWeekends: function () {
        this.scheduler.businessWeekends = this.businessWeekends;
        this.scheduler.update();
      },
      allowMultiMove: function () {
        this.scheduler.allowMultiMove = this.allowMultiMove;
        this.scheduler.update();
      },
      allowMultiResize: function () {
        this.scheduler.allowMultiResize = this.allowMultiResize;
        this.scheduler.update();
      },
      eventHeight: function () {
        this.scheduler.eventHeight = this.eventHeight;
        this.scheduler.update();
      },
      cellDurationDay: function () {
        this.scheduler.cellDuration = this.cellDurationDay;
        this.scheduler.update();
      },
      cellDurationWeek: function () {
        this.scheduler.cellDuration = this.cellDurationWeek;
        this.scheduler.update();
      },
      selectedViewProps: function (view) {
        this.viewModel = Object.assign({}, view);
        this.scheduler.rowHeaderColumns = [
          {
            html:
              '<div class="row-column-header"> View: ' +
              this.viewModel.name  || this.$t("planBoard.headers.name") +
              '</div>',
            width: 200
          },
        ];
        const viewPeriod = this.viewModel && this.viewModel.period ? this.viewModel.period : 'day';
        switch (viewPeriod){
          case 'day':
            if(this.showTotalColumn){
              this.scheduler.rowHeaderColumns.push({ name: this.$t("productLines.headers.total"), width: 55 })
            }
            this.dayScheduler();
            break;
          case 'week':
            if(this.showTotalColumn){
              this.scheduler.rowHeaderColumns.push({ name: this.$t("productLines.headers.total"), width: 55 })
            }
            this.weekScheduler();
            break;
          case 'month':
            this.monthScheduler();
            break;
        }
        this.scheduler.update();
      }
    },

    mounted() {
      this.activeBtnDay = true;
      this.activeBtnWeek = false;
      this.activeBtnMonth = false;
      this.dateStartSheduler = DayPilot.Date.today();
      this.daypilotScheduler = new DayPilot.Scheduler(this.id, {}).init();
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const app = this;
      app.scheduler.dynamicLoading = true
      const rowHeaderColumns = [{ title: this.$t("planBoard.headers.name"), width: 200 }]
        if(this.showTotalColumn){
          rowHeaderColumns.push({ name: this.$t("productLines.headers.total"), width: 55 })
        }
      Object.assign(this.daypilotScheduler, {
        //cornerHtml: '<div>iuifdgfdgsdgsdfgfdsgfdsgfdsgfdsgsdgfdsgfdsgfdsgfdsgfdgfdgfdutretretretreyiu</div>',
        //cornerHtml: "******* left-up conner text",
        treeImageNoChildren: "img/Error.svg",
        treeIndent: 5,
        treePreventParentUsage: true,
        durationBarVisible: false,
        heightSpec: "Auto",
        //height: 380,
        showNonBusiness: app.showNonBusiness,
        businessWeekends: app.businessWeekends,
        //infiniteScrollingEnabled: true,
        //infiniteScrollingStepDays: 300,
        locale: this.locale,
        theme: "BouwBit_Scheduler",

        allowMultiSelect: true,
        allowMultiMove: app.allowMultiMove,
        allowMultiResize: app.allowMultiResize,
        dragOutAllowed: true,
        eventStackingLineHeight: 100,
        rowMoveHandling: "Update",
        // eventMovingStartEndFormat : "MMMM d, yyyy. hh:MM",

        rowHeaderColumnsMode: "Legacy",
        rowHeaderColumns: rowHeaderColumns,
        onBeforeRowHeaderRender: args => {
          let totalRowHours = app.getTotalDuration(args)
          const columns = args.row.columns
          if(columns.length == 2) {
            totalRowHours = this.getTotalSubHeaders(args)
            args.row.columns[0].html = "<div style='margin-left: 4px'></div>"
          }
          if(columns[0] && columns[0].html) {
            if(this.showTotalInMinutes) {
              args.row.columns[0].html = "<div style='margin-left: 4px'> " + totalRowHours + " minuten </div>"
            } else {
              args.row.columns[0].html = "<div style='margin-left: 4px'> " + totalRowHours + " uur </div>"
            }

          }
        },

        onTimeRangeSelected: function (args) {
          app.addNewAppointment(args);
        },

        onEventMoving: function(args) {
          //
          if (args.e.resource() !== args.resource) {
            const data = args.e && args.e.data ? args.e.data : {};
            const serviceEngineerMoveToAll = !Object.prototype.hasOwnProperty.call(data,'serviceEngineerMoveToAll') ? true : data.serviceEngineerMoveToAll;

            if (serviceEngineerMoveToAll) {
              const resources = data[app.resourcesModule];

              if ((resources || []).length > 1) {
                args.left.enabled = false;
                args.allowed = false;
                return;
              }
            }
          }
          args.left.enabled = true;
          args.allowed = true;

          //https://doc.daypilot.org/scheduler/event-moving-indicators/
          args.left.html = args.start.toString("MMMM d, yyyy HH:mm", "nl-NL");
        },

        onScroll: async function(args) {
          if(app.viewportDate !== args.viewport.start) {
            app.viewportDate = args.viewport.start
            app.scheduler.update()
          }
        },
        onRowFilter: function (args) {
          if (app.typeOfResourcesFilter === "filter") {
            if (
              args.row.name.toUpperCase().indexOf(args.filter.toUpperCase()) ===
              -1
            ) {
              args.visible = false;
            }
          }
          if (app.typeOfResourcesFilter === "select") {
            if (!args.filter.length) {
              args.visible = true;
              return;
            }
            if (!args.filter.includes(args.row.name)) {
              args.visible = false;
            }
          }
        },

        timeRangeSelectedHandling: "Enabled",
        eventHeight: this.eventHeight,


        rowClickHandling: "Select",

        eventDoubleClickHandling: "Enabled",


        onEventDoubleClick: function (args) {
          app.showModalDoubleForm = true;
          app.appointmentID = args.e.data.ID;
          app.workOrderRecordID = args.e.data.wo.ID;
        },

        eventMoveHandling: "Update",
        onEventMoved: function (args) {
          app.appointmentAddMove(args);
        },

        eventResizeHandling: "Update",
        onEventResized: function (args) {
          app.appointmentResize(args);
        },

        eventClickHandling: "Select",
        onEventClicked: function (args) {
          this.multiselect.add(args);
        },

        //eventHoverHandling: "Enabled",
        treeEnabled: true,

        // Render function

        onBeforeEventRender: function (args) {
          args.data.barColor = args.data.color;
          args.data.backColor = args.data.lightColor;
          args.data.borderColor = args.data.color;
          args.e.bubbleHtml = args.data.textBalloon;
          args.data.areas = [
            /*{
              right: 5,
              top: 5,
              height: 10,
              width: 10,
              backColor: "#FFF",
              cssClass: "draggable",
              style: "cursor: move"
              //style: "cursor: move; border: 2px solid grey"
            },*/
            {
              left: -12,
              top: 0,
              height: 39,
              width: 8,
              backColor: args.data.color,
            }
          ]
        },

        /*onAfterEventRender: function(args) {
          //console.timeEnd()
          const data = args.e.data;
          const area = args.div.getElementsByClassName("draggable")[0];
          area.setAttribute("draggable", "true");
          area.addEventListener("dragstart", function(ev) {
            ev.dataTransfer.setData("daypilot/external-item", JSON.stringify(data));
          });
          area.addEventListener("mousedown", function(ev) {
            ev.stopPropagation();
          });
        },*/

        // TODO need to refactoring, very slowly x 10 time of the daypilot update

        onBeforeCellRender: function (args) {
          if(this.dateMap === undefined){
            this.dateMap = {"01": 0, "02": 31, "03": 59, "04": 90,"05": 120, "06": 151, "07": 181, "08": 212, "09": 243,"10": 273,"11": 304, "12": 334}
          }
          const splittedDate = args.cell.start.value.split("-")
          const day = splittedDate[2].slice(0,2)
          const month = splittedDate[1].slice(0,2)
          const dayOfYear = this.dateMap[month] + Number(day)
          if (dayOfYear % 2 === 0) {
            args.cell.backColor = "#70949c";
          } else {
            args.cell.backColor = "#83AAB3";
          }
        },

        onRowMoved: function(args) {
          const currentRow = app.scheduler.rowlist.find(r => r.resource.id === args.source.id);
          if (
            currentRow &&
            currentRow.resource &&
            currentRow.resource.user &&
            currentRow.resource.user.planBoardUserGroup &&
            args.target &&
            args.target.data &&
            args.target.data.user
          ) {
            currentRow.resource.user.planBoardUserGroup = args.target.data.user.planBoardUserGroup ;
            if(app.viewModel.ID) app.onSaveView()
          }
          else if (
            currentRow &&
            currentRow.resource &&
            currentRow.resource.user &&
            currentRow.resource.user.planBoardUserGroup &&
            args.target &&
            args.target.data &&
            !Object.prototype.hasOwnProperty.call(args.target.data, 'user') &&
            args.target.data.id
          )
          {
            currentRow.resource.user.planBoardUserGroup = args.target.data.id ;
            if(app.viewModel.ID) app.onSaveView()
          }
        },
        onRowMoving: function(args) {
          if (!args.source.data.user) {
            args.position = "forbidden";
          }
        },
        // eslint-disable-next-line
        contextMenu: {},
        contextMenuResource: {},

      });

      this.daypilotScheduler.contextMenu = this.initContextMenu();
      this.daypilotScheduler.contextMenuResource = this.initContextMenuResource();
      this.getGlobalPlanboardSettings()
      this.setPeriodFromScheduler();
      this.dayScheduler();
      this.loadResources();
      this.currentSeparatorsUpdateByTimer();
      this.checkRangeChangeTimer = setInterval(this.checkRangeChange, 2000);
      // this.initDropTarget();

      this.dayPilotQueue = new DayPilot.Queue("queue");
      this.dayPilotQueue.onEventMoved = function (args) {
        if (args.external) {

          new AppointmentModel().dragAndDropFromDayPilot(args.e.data);
          app.$emit('dragAndDropFromDayPilot');
          this.dayPilotQueue.events.remove(args.e.data.ID)
        }
      };
      this.dayPilotQueue.init();
    },
    beforeDestroy() {
      this.debouncedTaskFilter.cancel()
      clearInterval(this.checkRangeChangeTimer);
      this.checkRangeChangeTimer = null;
      this.daypilotScheduler.dispose()
      this.dayPilotQueue.dispose()
    },
  };
