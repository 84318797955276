
  import AppointmentModel from "../Models/AppointmentModel";
  import store from "@/store";

  export default {
    name: "AssignMultiple",
    props: {
      taskAssignMultiple: {},
      techniciansList: {},
      planboardSchedulerSettings: {}
    },
    data() {
      return {
        assignedServiceEngineer: [],
        responsibleServiceEngineer: [],
        moveToAllFlag: false,
        resourcesModule: this.planboardSchedulerSettings && this.planboardSchedulerSettings.resourcesModule ? this.planboardSchedulerSettings.resourcesModule : 'users',
        resourcesModuleField: 'user'
      };
    },

    methods: {
      async assignMultiple() {
        this.resourcesModuleField = store.state.allModules[this.resourcesModule].singularName;
        let id = this.taskAssignMultiple.id.split('_||_')[0];
        const resWithNames = this.techniciansList
          .filter(t => this.assignedServiceEngineer.map(a => {
            return a && a.ID ? a.ID : a;
          }).includes(t.id))
          .map(r => {
            return {ID: r.id, name: r.name}
          });
        
        // split the appointment to each user
        if(!this.moveToAllFlag && this.assignedServiceEngineer.length > 1) {
          const model = new AppointmentModel();
          const appointmentSaved = await model.getByID(id, 'appointments');
          this.assignedServiceEngineer.map(u => {
            const uName = resWithNames.find(r => (r.ID === u || r.ID === u.ID));
            
            if (uName) {
              if (id) {
                const data = {
                  id: id,
                  [this.resourcesModule]: [u],
                  ['__' + this.resourcesModule]: [uName],
                  serviceEngineerMoveToAll: false
                };
                this.$emit("receiveAssignedMultiple", data);
                // 
              } else {
                const data = {
                  ...appointmentSaved, ...{
                    id: id,
                    ID: id,
                    [this.resourcesModuleField || 'user']: uName,
                    [this.resourcesModule]: [u],
                    ['__' + this.resourcesModule]: [uName],
                    serviceEngineerMoveToAll: false
                  }
                };
                this.$emit("receiveAssignedMultiple", data);
              }
            }
            id = null;
          });
        } else {
          const data = {id: id, [this.resourcesModule]: this.assignedServiceEngineer, ['__' + this.resourcesModule]: resWithNames, serviceEngineerMoveToAll: this.moveToAllFlag};
          this.$emit("receiveAssignedMultiple", data);
          // 
        }
      },
      cancelAssignedMultiple: function () {
        this.$emit("receiveCancelAssignedMultiple");
      },
    },
    computed: {
      techniciansListSorted() {
        return this.techniciansList.map(a => a).sort((a, b) => {
          if (a.name.toLowerCase() === b.name.toLowerCase()) return 0
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
          return 0
        });
      }
    },
    watch: {},
    mounted: function () {
      if (typeof this.taskAssignMultiple.serviceEngineerMoveToAll !== "undefined") {
        this.moveToAllFlag = this.taskAssignMultiple.serviceEngineerMoveToAll;
      } else {
        this.moveToAllFlag = true;
      }
      this.assignedServiceEngineer = this.taskAssignMultiple[this.resourcesModule];
    },
  };
