
  import ListValues from "@/components/Mixin/ListValues";
  import dynamic from "@/store/dynamic";
  import TableMixin from "../../Mixin/TableMixin";
  import {DayPilot} from "daypilot-pro-vue";
  import BaseModalForms from '@/components/Base/Mixin/BaseModalForms';
  import { mapState } from "vuex";

  export default {
    name: "DynamicTable",
    components: { BaseModalForms },
    mixins: [TableMixin],
    props: {
      title: null,
      module: null,
      filters: null,
      searchFilter: null,
      moduleSettings: null,
      openAsRelated: null,
      columns: null,
      visibleDefaultColumns: null,
      defaultColumns: null,
      togleRefreshList: null,
      onRowClickOver: null,
      onRowDblClickOver: null,
      onRowClickOverWorkOrder: null,
      columnSortEnabled: null,
      dynModuleName: null,
      dynFields: null,
      selectedString: null,
      paginationLabel: null,
      defaultPagination: null,
      filteredDataSaved: null,
      exData: null,
      isPlanBoardMode: null,
      showPlanboadrHeader: null,
    },
    data: () => ({
      loading: false,
      separator: "horizontal",
      pagination: {
        sortBy: "changedAt",
        descending: true,
      },
      selected: [],
      visibleColumns: [],
      deleteBtnVisible: false,
      draggerHeaders: null,
      makeDraggableTimer: null,
      showRelatedModalForm: false,
      modalRelatedFormID: null,
      relatedModuleModal: '',

      // QGrid
      columnOptionsSelected: {},
      filterData: {},
      uuid: '',
      columnOptions: {},
      filterFlags: {},
      currentRefreshKey: 1,
      defaultPaginationLoaded: false,
    }),
    methods: {
      customSort (rows, sortBy, descending) {
        return ListValues.customSort(rows, sortBy, descending);
      },
      refreshList() {
        this.pagination.page = 1;
        this.runMakeDraggable();
      },
      filterChanged() {
        this.savePaginationSettings();
      },
      savePaginationSettings() {
        
        const pagination = this.pagination;
        this.$emit('savePaginationSettings', {
          rowsPerPage : pagination.rowsPerPage,
          sortBy : pagination.sortBy,
          descending : pagination.descending,
          page : pagination.page,
          filterData: this.filterData,
          columnOptionsSelected: this.columnOptionsSelected,
        });
      },
      onRowClick(evt, row) {
        if (this.onRowClickOver) {
          this.onRowClickOver(evt, row);
        }
      },
      onRowClickWorkOrder(row) {
        if (this.onRowClickOverWorkOrder) {
          this.onRowClickOverWorkOrder(row);
        }
      },
      onRowDblClick(evt, row) {
        if (this.onRowDblClickOver) {
          this.onRowDblClickOver(evt, row);
        }
      },

      runMakeDraggable() {
        if(!this.isPlanBoardMode) {
          return false;
        }
        setTimeout(this.makeDraggable, 500);
        setTimeout(this.makeDraggable, 1000 * 1);
        if (!this.makeDraggableTimer) {
          this.makeDraggableTimer = setInterval(this.makeDraggable, 2000);
        }
      },
      async makeDraggable() {
        
        const elements = document.getElementsByClassName("j-order-id");
        for (let i = 0; i < elements.length; i++) {
          DayPilot.Scheduler.makeDraggable({
            element: elements[i].parentElement.parentElement,
            orderID: elements[i].getAttribute("data-id"),
            appoID: elements[i].getAttribute("appo-id"),
            keepElement: true,
          });
        }
      },

      // QGrtid
      setColumnFilterOptions(cols) {
        const off = false;
        if (off) {
          const columnOptions = {};
          cols.filter((item) => {
            columnOptions[item.field] = [];
            this.$set(this.columnOptionsSelected, item.field, []);
            this.filterFlags[item.field] = false;
            return item
          });

          this.data.filter((item) => {
            cols.filter(function (column) {
              if (item[column.field] && column.filterType === 'select') {
                columnOptions[column.field].push({
                  label: item[column.field].toString(),
                  value: item[column.field].toString().toLowerCase().replace(/_/g, '_')
                })
              }
            });
          });

          cols.filter((column) => {
            if (column.filterType === 'select') {
              columnOptions[column.field].push({ label: '-', 'value': '' });
              columnOptions[column.field] = [...new Map(columnOptions[column.field].map(item =>
                [item['value'], item])).values()];
            }
          });
          this.columnOptions = columnOptions;
          return columnOptions;
        } else {
          const columnOptions = {};
          cols.filter((item) => {
            columnOptions[item.field] = [];
            this.$set(this.columnOptionsSelected, item.field, []);
            this.filterFlags[item.field] = false;
            return item
          });

          for(const column of cols) {
            const field = column.field.split(".")[0]
            const module = column.field.split(".")[1]
            const dropDownValues = this.dynamicModules[module].dropDownValues[field]
            for(const value in dropDownValues) {
              columnOptions[column.field].push({
                label: dropDownValues[value],
                value: dropDownValues[value].toLowerCase()
              })
            }
          }
          this.columnOptions = columnOptions;
          return columnOptions;
        }
      },
    },
    beforeMount() {
      this.refreshList();
    },
    beforeDestroy() {
      clearInterval(this.makeDraggableTimer);
      this.makeDraggableTimer = null;
    },
    watch: {
      pagination() {
        this.savePaginationSettings();
      },
      defaultPagination() {
        if (!this.defaultPaginationLoaded && this.defaultPagination) {
          this.pagination = { ...this.defaultPagination };
          this.defaultPaginationLoaded = true;
        }
      },
    },
    computed: {
      ...mapState(['dynamicModules']),
      data() {
        if (this.isPlanBoardMode) {
          return this.exData;
        }
        if (this.module) {
          return ListValues.transform(dynamic.state[this.module] || [], this.dynFields, true);
        } else {
          
          return [];
        }
      },
      getFilteredData() {
        const tableColumns = [].concat(['ID'], this.visibleColumns.map((item) => {
          return item
        }));
        const tableData = this.data.filter((item) => {
          let i = '';
          for (i = 0; i < tableColumns.length; i++) {
            if (this.filterData[tableColumns[i]] == '')
              continue;

            if (item[tableColumns[i]] !== undefined) {
              const colName = tableColumns[i];
              const inFilter = colName in this.filterData;
              const value = (colName === 'ID')

                ? item && item.workOrder && item.workOrder.name

                : (item[tableColumns[i]] && item[tableColumns[i]].name) ? item[tableColumns[i]].name : item[tableColumns[i]];

              if (inFilter && (!value || value.toString().toLowerCase().indexOf(this.filterData[colName].toLowerCase()) == -1)) {
                return false;
              }
            }
          }
          return true
        });
        return tableData
      },
      getFilteredValuesData() {
        const tableData = this.getFilteredData.filter((item) => {
          let i = '';
          for (i = 0; i < this.visibleColumns.length; i++) {
            if (this.visibleColumns[i] && this.columnOptionsSelected[this.visibleColumns[i]] && this.columnOptionsSelected[this.visibleColumns[i]].length == 0)
              continue;

            const itemValue = item[this.visibleColumns[i]] ? item[this.visibleColumns[i]].toString().toLowerCase() : '';
            if (this.visibleColumns[i] && this.columnOptionsSelected[this.visibleColumns[i]] && this.columnOptionsSelected[this.visibleColumns[i]].indexOf(itemValue) == -1) {
              return false;
            }
          }
          return true
        });
        return tableData;
      },
    },
  };
