import { render, staticRenderFns } from "./ModalForDoubleForm.vue?vue&type=template&id=c5d8b5b2&scoped=true&"
import script from "./ModalForDoubleForm.vue?vue&type=script&lang=js&"
export * from "./ModalForDoubleForm.vue?vue&type=script&lang=js&"
import style0 from "./ModalForDoubleForm.vue?vue&type=style&index=0&id=c5d8b5b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d8b5b2",
  null
  
)

export default component.exports
import {QCard,QTabs,QTab,QBtn,QSeparator,QTabPanels,QTabPanel,QDialog,QCardSection,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QTabs,QTab,QBtn,QSeparator,QTabPanels,QTabPanel,QDialog,QCardSection,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
