

import {mapState} from "vuex";
import DynamicList from "@/components/Forms/Firestore/DynamicList";
import ModalForDoubleForm from './ModalForDoubleForm';

export default {
  components: {
    DynamicList, ModalForDoubleForm
  },
  props: {
    data: {},
    dynamicModuleWO: {},
    dynamicModuleAP: {},
    dynFieldsAll: {},
    doRefreshOrders: null,
    planboardListSettings: null
  },
  data: () => ({
    orderPagination: {
      page: 1,
      rowsPerPage: 30,
    },
    separator: "horizontal",
    showDropdownSettings: false,
    columnSortEnabled: false,
    draggerHeaders: null,
    filterByName:null,

    //modal
    showModalDoubleForm: false,
    appointmentRecordID: null,
    workOrderRecordID: null,
  }),
  methods: {
    addNewAppointment() {
      this.openModalDoubleForm ({ orderID: '', appID: '' })
    },
    openWorkOrderModal(row) {
      this.openModalDoubleForm (row)
    },
    openAppointmentModal(evt, row) {
      this.openModalDoubleForm (row)
    },
    openModalDoubleForm (row) {
      this.workOrderRecordID = row.orderID;
      this.appointmentRecordID = row.appID;
      this.showModalDoubleForm = true;
    },
    resetShowModalDoubleForm() {
      this.$emit('onRefreshWorkOrdersData', this.workOrderRecordID);
      this.workOrderRecordID = null;
      this.appointmentRecordID = null;
      this.showModalDoubleForm = false;
    },
    onSavePlanboardListSettings(settings){
      this.$emit('onSavePlanboardListSettings', settings);
    }
  },

  computed: {
    ...mapState("subscribe", {dataLoading: "loading"}),

    getVisibleColumns() {
      return ['ID'].concat(this.visibleColumns)
    },

    dynFields() {
      return this.dynFieldsAll;
    },

    columnsOrders() {
      return [{
        name: 'ID',
        align: this.align,
        label: 'ID',
        field: 'ID',
      }].concat(this.columns);

    },

  },

  watch: {
    doRefreshOrders() {
      // this.runMakeDraggable();
    }
  }

};
